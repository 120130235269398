<template>
  <v-container fluid class="pa-0" style="height: 100vh">

    <!-- shows full loading page when first fetching info -->
    <v-row v-if="loading && !view" align="center" justify="center" style="height: inherit;">
      <v-progress-circular indeterminate size="100" width="4" color="primary">
      </v-progress-circular>
    </v-row>

    <v-row v-else-if="view" align="stretch" style="height: inherit;" no-gutters>
      <!-- shows linear loader on top when just refreshing info -->
      <v-progress-linear v-if="loading" absolute indeterminate color="primary" height="3">
      </v-progress-linear>
      <v-col>
        <v-card :elevation="0" class="rounded-0"
          style="border: 3px solid var(--v-warning-base); border-top: none; border-right: none; border-bottom: none;">
          <v-card-title class="py-5">
            <CountCard :count="UpcomingJobsCount" color="warning" class="mr-2" />
            {{ $t('partner.job.planned') }}
          </v-card-title>
          <v-list v-if="UpcomingJobsCount" class="py-0">
            <SimpleJobListItem v-for="job in view.Upcoming" :key="job.Id" :item="job" @click="start(job)">
            </SimpleJobListItem>
          </v-list>
          <v-card-text v-else>{{ $t('partner.job.no_planned_jobs') }}</v-card-text>
        </v-card>

        <v-divider></v-divider>
        <v-divider></v-divider>

        <v-card :elevation="0" class="rounded-0"
          style="border: 3px solid var(--v-primary-base); border-top: none; border-right: none; border-bottom: none;">
          <v-card-title class="py-5">
            <CountCard :count="OpenJobsCount" class="mr-2" />
            {{ $t('partner.job.open') }}
          </v-card-title>
          <v-list v-if="OpenJobsCount" class="py-0">
            <SimpleJobListItem v-for="job in view.Open" :key="job.Id" :item="job" @click="start(job)">
            </SimpleJobListItem>
          </v-list>
          <v-card-text v-else>{{ $t('partner.job.no_open_jobs') }}</v-card-text>
        </v-card>

        <v-divider></v-divider>
        <v-divider></v-divider>

        <v-card :elevation="0" class="rounded-0"
          style="opacity: 0.8; border: 3px solid var(--v-disabled-base); border-top: none; border-right: none; border-bottom: none;">
          <v-card-title class="py-5" style="opacity: 0.7;">
            <CountCard :count="FinishedJobsCount" color="disabled darken-2" class="mr-2" />
            {{ $t('partner.job.completed') }}
          </v-card-title>
          <v-list v-if="FinishedJobsCount" class="py-0">
            <SimpleJobListItem v-for="job in view.Finished" :key="job.Id" :item="job" @click="start(job)">
            </SimpleJobListItem>
          </v-list>
          <v-card-text v-else>{{ $t('partner.job.no_completed_jobs') }}</v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row v-else align="center" justify="center" style="height: inherit;">
      <EmptyPage :icon="'mdi-alert'" :title="$t('partner.job.error_loading_jobs_title')"
        :message="$t('partner.job.error_loading_jobs')">
      </EmptyPage>
    </v-row>

    <StartJobDialog :opentrigger="dialogTrigger" :jobId="selectedJobId">
    </StartJobDialog>

  </v-container>
</template>


<script>
import SimpleJobListItem from "@/components/ListItems/SimpleJobListItem.vue";
import EmptyPage from "@/views/EmptyPage.vue";
import StartJobDialog from "@/components/Dialogs/StartJobDialog.vue";
import CountCard from "@/components/CountCard.vue";

export default {
  name: 'PartnerJobList',
  props: {},
  inject: {
    jobsWrapper: { //set up as a function to be returned in computed to be reactive
      type: Function,
      default: () => { },
    },
  },
  components: {
    SimpleJobListItem,
    EmptyPage,
    StartJobDialog,
    CountCard,
  },

  data() {
    return {
      selectedJobId: null,
      dialogTrigger: 1,
    };
  },

  mounted() {
    this.reactiveJobsWrapper.refreshFunc();
  },

  methods: {
    start(job) {
      if (job.JobStatus.Id > 20) {
        this.$router.push({ name: 'ViewJob', params: { id: job.Id } });
      }
      else {
        this.selectedJobId = job.Id;
        this.dialogTrigger++;
      }
    },
  },

  computed: {
    UpcomingJobsCount() {
      return this.view.Upcoming?.length ?? 0;
    },
    OpenJobsCount() {
      return this.view.Open?.length ?? 0;
    },
    FinishedJobsCount() {
      return this.view.Finished?.length ?? 0;
    },
    reactiveJobsWrapper() {
      return this.jobsWrapper();
    },
    loading() {
      return this.reactiveJobsWrapper.loading;
    },
    view() {
      return this.reactiveJobsWrapper.view;
    },
    error() {
      return this.reactiveJobsWrapper.error;
    },
  },

  watch: {},
};
</script>


<style lang='scss' scoped></style>