<template>
    <v-list-item :dense="!isPhoneBool" link class="pr-2" style="border-bottom: 1px solid rgba(0, 0, 0, 0.1);"
        @click="$emit('click')">
        <v-list-item-content>
            <v-list-item-title>
                <b class="mr-1">{{ item.Order && item.Order.Id || 'S' + item.Id }}</b> {{ customertext }}
            </v-list-item-title>
            <v-list-item-subtitle v-if="isPhoneBool" @click.stop="">
                <v-btn text small :color="item.JobStatus.Id < 50 ? 'primary' : ''" class="px-0"
                    style="text-transform: none;" :href="Address?.mapslink" :target="'_blank'">
                    <v-icon size="13">mdi-map-marker</v-icon>
                    {{ addresstext }}
                </v-btn>
            </v-list-item-subtitle>
            <v-list-item-title>
                <JobTypeChip :typeId="item.JobType.Id" :typeName="item.JobType.Name" class="mt-1 mb-2">
                </JobTypeChip>
            </v-list-item-title>
            <template v-if="isPhoneBool">
                <AppointmentSubtitle v-if="item.JobStatus.Id < 50" :appointments="item.Appointments" />
                <v-list-item-subtitle v-else>
                    <i>Finished {{ completedDate }}</i>
                </v-list-item-subtitle>
            </template>
        </v-list-item-content>
        <v-list-item-content v-if="!isPhoneBool">
            <v-list-item-subtitle @click.stop="">
                <v-btn text small :color="item.JobStatus.Id < 50 ? 'primary' : ''" class="px-0"
                    style="text-transform: none;" :href="Address?.mapslink" :target="'_blank'">
                    <v-icon size="13">mdi-map-marker</v-icon>
                    {{ addresstext }}
                </v-btn>
            </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-content v-if="!isPhoneBool">
            <AppointmentSubtitle v-if="item.JobStatus.Id < 50" :appointments="item.Appointments" />
            <v-list-item-subtitle v-else>
                <i>Finished {{ completedDate }}</i>
            </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action class="ma-auto ml-2">
            <v-btn icon>
                <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
        </v-list-item-action>
    </v-list-item>
</template>


<script>
import AppointmentSubtitle from './AppointmentSubtitle.vue';
import JobTypeChip from '../JobTypeChip.vue';

export default {
    name: 'SimpleJobListItem',
    props: {
        item: {
            type: Object,
            required: true,
        },
    },
    components: {
        AppointmentSubtitle,
        JobTypeChip,
    },

    data() {
        return {};
    },

    methods: {},

    computed: {
        completedDate() {
            if (!this.item.CompletedDate) {
                return '';
            } else {
                return new Date(this.item.CompletedDate).toLocaleString();
            }
        },

        Address() {
            if (this.item.CustomerDeliveryAdressStreet ||
                this.item.CustomerDeliveryAdressZip ||
                this.item.CustomerDeliveryAdressCity ||
                this.item.CustomerDeliveryAdressCountry) {
                var address = {
                    street: this.item.CustomerDeliveryAdressStreet,
                    zip: this.item.CustomerDeliveryAdressZip,
                    city: this.item.CustomerDeliveryAdressCity,
                    country: this.item.CustomerDeliveryAdressCountry
                }
                address.mapslink = `http://maps.google.com/?q=${address.street}, ${address.zip} ${address.city}, ${address.country}`;
                return address;
            }
            else {
                return null;
            }
        },

        addresstext() {
            var addresstext = this.Address ?
                `${this.Address.street}, ${this.Address.zip} ${this.Address.city}, ${this.Address.country}` :
                '';
            return addresstext;
        },

        customertext() {
            var name = this.item.Customer.Name;
            return name;
        },
    },

    watch: {},
};
</script>


<style lang='scss' scoped></style>