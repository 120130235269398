<template>
  <v-navigation-drawer app v-model="drawer" :width="drawerWidth"> <!-- dark color="tertiary" -->
    <v-card tile flat :to="{ name: 'Customer' }" color="transparent">
      <v-container class="pt-2 pb-0 px-0">
        <v-row justify="center" class="pb-1" no-gutters>
          <v-img src="/api2/Report/logo" height="52" max-width="110" contain :alt="userInfo.Provider.CompanyName"
            position="center">
          </v-img>
        </v-row>
        <v-row justify="center" class="py-1" style="background-color: rgb(0 0 0 / 3%);" no-gutters>
          {{ $t("customer.portal.title") }}
        </v-row>
      </v-container>
    </v-card>
    <v-card tile flat class="text-center py-1" style="background-color: rgb(0 0 0 / 7%);" href="https://www.qwips.no"
      target="_blank">
      <small style="opacity: 0.7; font-weight: 400;">Powered by Qwips</small>
    </v-card>
    <v-divider />

    <v-list dense class="py-0">
      <!-- 
      <NavTile :title="$t('text.home')" icon="mdi-home" :route="{ name: 'FrontPage' }">
      </NavTile>
      -->

      <!-- 
      <NavTile :title="'Shop'" icon="mdi-shopping" :route="{ name: 'Shop' }" :count="shoppingCartCount">
      </NavTile>
      -->
      <NavTile :title="$t('route.Machines')" icon="mdi-washing-machine" :route="{ name: 'Machines' }">
      </NavTile>
      <NavTile :title="$t('text.shopping_cart')" icon="mdi-cart" :route="{ name: 'ShoppingCart' }"
        :count="shoppingCartCount">
      </NavTile>
      <NavTile :title="$t('text.history')" icon="mdi-history" :route="{ name: 'JobList' }" :count="openJobsCount"
        :countColor="'blue'">
      </NavTile>
      <NavTile :title="$t('text.organization')" icon="mdi-domain" :route="{ name: 'Organization' }">
      </NavTile>
      <!-- 
      <NavTile :title="$t('text.more')" icon="mdi-dots-horizontal" :route="{ name: 'Organization' }">
      </NavTile>
      -->
    </v-list>

    <v-row no-gutters :style="'width: ' + drawerWidth + 'px;'" style="position: fixed; bottom: 0px; z-index: 100;">
      <AccountButton />
    </v-row>
  </v-navigation-drawer>
</template>


<script>
//import DirectorySwitcher from "@/components/Inputs/DirectorySwitcher.vue";
import AccountButton from "@/components/AccountButton.vue";
//import GlobalSearchField from "@/components/GlobalSearchField.vue";
//import ShoppingCartButton from "@/components/ShoppingCartButton.vue";
import NavTile from "@/components/NavTile.vue";

export default {
  name: "SideBar",
  components: {
    //DirectorySwitcher,
    AccountButton,
    //GlobalSearchField,
    //ShoppingCartButton,
    NavTile,
  },

  data() {
    return {
      drawer: true,
      drawerWidth: 130,
    };
  },

  mounted() { },

  methods: {},

  computed: {
    userInfo() {
      if (this.$store.state.userInfo) return this.$store.state.userInfo;
      return { Customer: {}, Provider: {} };
    },

    shoppingCartCount() {
      return this.$store.getters.shoppingCartCount;
    },

    openJobsCount() {
      return this.$store.state.openJobsCount;
    },
  },

  watch: {},
};
</script>

<style lang="scss"></style>