<template>
  <v-navigation-drawer app v-model="drawer" :width="drawerWidth"> <!-- dark color="tertiary" -->
    <v-card tile flat :to="{ name: 'Partner' }" color="transparent">
      <v-container class="pt-2 pb-0 px-0">
        <v-row justify="center" class="pb-1" no-gutters>
          <v-img src="/api2/Report/logo" height="52" max-width="110" contain :alt="userInfo.Provider.CompanyName"
            position="center">
          </v-img>
        </v-row>
        <v-row justify="center" class="py-1" style="background-color: rgb(0 0 0 / 3%);" no-gutters>
          {{ $t("partner.portal.title") }}
        </v-row>
      </v-container>
    </v-card>


    <v-divider />

    <v-list dense class="py-0">
      <NavTileComponent v-for="item in items" :key="item.title" :route="item.route" :title="item.title"
        :icon="item.icon" :count="item.count">
      </NavTileComponent>
    </v-list>

    <v-row no-gutters :style="'width: ' + drawerWidth + 'px;'" style="position: fixed; bottom: 0px; z-index: 100;">
      <v-col>
        <AccountButton />
        <v-card tile flat class="text-center py-1" style="background-color: rgb(0 0 0 / 9%);"
          href="https://www.qwips.no" target="_blank">
          <small style="font-weight: 400;">Powered by <b>Qwips</b></small>
        </v-card>
      </v-col>
    </v-row>
  </v-navigation-drawer>
</template>


<script>
import AccountButton from "@/components/AccountButton.vue";
import NavTileComponent from "@/components/NavTileComponent.vue";

export default {
  name: "SideBar",
  props: {
    items: Array
  },
  components: {
    AccountButton,
    NavTileComponent,
  },

  data() {
    return {
      drawer: true,
      drawerWidth: 130,
    };
  },

  mounted() { },

  methods: {},

  computed: {
    userInfo() {
      if (this.$store.state.userInfo) return this.$store.state.userInfo;
      return { Customer: {}, Provider: {} };
    },

    shoppingCartCount() {
      return this.$store.getters.shoppingCartCount;
    },

    openJobsCount() {
      return this.$store.state.openJobsCount;
    },
  },

  watch: {},
};
</script>

<style lang="scss"></style>