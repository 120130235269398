import EmptyRouterView from "@/views/EmptyRouterView.vue";
import PartnerCode from "@/views/Partner/PartnerCode.vue";
import Partner from "@/views/Partner/Partner.vue";
//import PartnerDashboard from "@/views/Partner/PartnerDashboard.vue";
import PartnerJobList from "@/views/Partner/Jobs/PartnerJobList.vue";
import PartnerCodeJob from "@/views/Partner/Jobs/PartnerCodeJob.vue";
import ViewJob from "@/views/Partner/Jobs/ViewJob.vue";
import ViewPartnerCompany from "@/views/Partner/ViewPartnerCompany.vue";

const routes = [
    {
        path: "/c/:code",
        component: PartnerCode,
        name: "PartnerCode"
    },
    {
        path: "/partner",
        component: Partner,
        name: "Partner",
        redirect: { name: "PartnerJobs" },
        children: [
            /*
            {
                meta: {
                    title: "Dashboard",
                },
                path: "dashboard",
                name: "PartnerDashboard",
                component: PartnerDashboard,
            },
            */
            {
                path: "job",
                name: "PartnerJobs",
                component: EmptyRouterView,
                children: [
                    {
                        path: "list",
                        name: "PartnerJobList",
                        component: PartnerJobList,
                    },
                    {
                        path: ":id",
                        name: "ViewJob",
                        component: ViewJob,
                    },
                    {
                        name: "PartnerCodeJob",
                        path: "view/:code",
                        component: PartnerCodeJob
                    }
                ],
            },
            {
                name: "ViewPartnerCompany",
                path: "company",
                component: ViewPartnerCompany,
            },
        ],
    },
];

export default routes;
