<template>
  <div>
    <v-form v-model="valid" ref="form">

      <p>{{ email }}</p>
      <v-text-field v-model="newPassword" :label="$t('text.new_password')"
        :append-icon="showPw ? 'mdi-eye' : 'mdi-eye-off'" :type="showPw ? 'text' : 'password'" :rules="passwordRules"
        counter outlined dense class="mb-2" @click:append="showPw = !showPw">
      </v-text-field>
      <v-text-field v-model="newPasswordConfirm" :label="$t('text.confirm_password')"
        :append-icon="showPw2 ? 'mdi-eye' : 'mdi-eye-off'" :type="showPw2 ? 'text' : 'password'"
        :rules="confirmPasswordRules" counter outlined dense @click:append="showPw2 = !showPw2">
      </v-text-field>

      <WideBtn :text="$t('text.confirm')" color="var(--v-tertiary-base)" :disabled="!valid" :loading="taskInProgress"
        @click="setPasswordByOtp()">
      </WideBtn>
      <WideBtn :text="$t('text.cancel')" color="var(--v-tertiary-base)" :disabled="taskInProgress" :filled="false"
        @click="mode = null">
      </WideBtn>

      <v-snackbar v-model="snackbar" :color="success ? 'green' : 'error'">{{ snackbarText }}</v-snackbar>
    </v-form>
  </div>
</template>


<script>
import http from "@/api/client";
import WideBtn from '@/components/WideBtn.vue';

export default {
  name: "SetPwContent",
  components: {
    WideBtn,
  },

  data() {
    return {
      snackbar: false,
      snackbarText: "",
      taskInProgress: false,
      valid: false,

      showPw: false,
      showPw2: false,
      newPassword: "",
      newPasswordConfirm: "",
      passwordRules: [
        (value) => !!value || "Please type password.",
        (value) => (value && value.length >= 6) || "Minimum 6 characters",
        (value) =>
          (value && /[A-Z]{1}/.test(value)) || "At least one capital latter",
        (value) => (value && /\d/.test(value)) || "At least one digit",
        (value) =>
          (value && /[^A-Za-z0-9]/.test(value)) ||
          "At least one special character",
      ],
      confirmPasswordRules: [
        (value) => !!value || "Please type confirm password",
        (value) =>
          value === this.newPassword ||
          "The password confirmation does not match.",
      ],
    };
  },

  mounted() { },

  methods: {
    setPasswordByOtp() {
      this.onStart();
      const dto = { Email: this.email, Code: this.otp, Password: this.newPassword };
      http()
        .post(`/api2/Accounts/ChangePassword`, dto)
        .then(() => {
          this.onSuccess("", "Password changed successfully.");
        })
        .catch((error) => {
          this.onError(error, error.response.data.Message);
        });
    },

    onStart() {
      this.taskInProgress = true;
      this.success = true;
      this.snackbar = false;
    },

    onSuccess(message, snacktext) {
      this.message = message;
      this.success = true;
      this.snackbarText = snacktext;
      this.snackbar = true;
      this.taskInProgress = false;
      this.login();
    },

    onError(error, snacktext) {
      console.error(error);
      this.success = false;
      this.snackbarText = snacktext;
      this.snackbar = true;
      this.taskInProgress = false;
    },

    login() {
      this.loginInProgress = true;
      this.$store
        .dispatch("customerLogin", {
          username: this.email,
          password: this.newPassword,
        })
        .then((result) => {
          if (!result.success) {
            this.snackbarText = result.errorMessage;
            this.snackbar = true;
          }
          this.loginInProgress = false;
        });
    },
  },

  computed: {
    email: {
      get() {
        return this.getQueryParam("email", "");
      },
      set(update) {
        this.setQueryParam("email", update);
      },
    },

    otp() {
      return this.getQueryParam("otp", "");
    },

    mode: {
      get() {
        return this.getQueryParam("mode", "");
      },
      set(update) {
        this.setQueryParam("mode", update);
      },
    },
  },

  watch: {},
};
</script>


<style lang="scss"></style>