<template>
  <v-list class="py-0">
    <v-progress-linear v-if="loading" indeterminate absolute>
    </v-progress-linear>
    <!-- <pre>{{ resources }}</pre>    -->
    <template v-if="anyResources">

      <v-card class="rounded-0 px-4 pt-3 pb-1" flat>
        <v-row no-gutters align="center">
          <v-col cols="12" class="pr-3" style="max-width: 240px">
            <FilterSearch :queryKey="'resource_search'" :limitWidth="false" />
          </v-col>
          <v-col :cols="wide ? 6 : 12">
            <v-row no-gutters>
              <v-chip-group v-model="selection" mandatory active-class="primary" column>
                <v-chip v-for="chip in filterChipsOptions" :key="'chip' + chip.value" :value="chip.value" class="mr-1"
                  small>
                  {{ chip.text }}
                </v-chip>
              </v-chip-group>
              <v-btn v-if="hasFilters" x-small outlined style="position: absolute; bottom: 12px; right: 16px;"
                color="error" @click="resetFilter()">
                <v-icon size="14" class="ml-n1">mdi-filter-off</v-icon>
                {{ $t('text.clear') }}
              </v-btn>
            </v-row>
          </v-col>
        </v-row>
      </v-card>

      <Folder :folder="typeFilteredResources" :totalSearchCount="searchResultCount" :wide="wide">
      </Folder>

      <div v-if="numberOfHiddenFiles" class="d-flex flex-column justify-center align-center flex-wrap px-3 py-16 ">
        <small style="opacity: 0.7;">{{ $t('text.did_you_not_find_what_you_where_looking_for') }}</small>
        <small style="opacity: 0.7;"><b class="primary--text">{{ numberOfHiddenFiles }}</b>
          {{ $t('text.files_hidden_by_filter') }}</small>
        <v-btn small text color="blue" class="px-1" @click="resetFilter()">{{ $t('text.clear_filter') }}</v-btn>
      </div>
    </template>

    <div v-else class="pa-3">
      No resources available for this equipment.
    </div>
  </v-list>
</template>


<script>
import Folder from "@/components/Folder.vue";
import FilterSearch from "@/components/Inputs/FilterSearch.vue";
import FolderMixin from "@/helpers/mixins/FolderMixin.js";

export default {
  name: "FolderList",
  props: {
    allowed: Boolean,
    resources: Object,
    loading: Boolean,
    wide: Boolean,
  },
  components: {
    FilterSearch,
    Folder,
  },
  mixins: [FolderMixin],

  data() {
    return {
      selection: 0,
      searchFilteredResources: this.resources,
      typeFilteredResources: this.resources,
    };
  },

  mounted() {
    //console.log('FolderList', this.resources)
  },

  methods: {
    resetFilter() {
      this.selection = 0;
      this.$router.replace({ query: { ...this.$route.query, 'resource_search': undefined } });
    },

    filter() {
      if (!this.resources) { return; }
      var copy = JSON.parse(JSON.stringify(this.resources));
      this.searchFilteredResources = this.filterFiles(this.searchword, null, copy);
      var copy2 = JSON.parse(JSON.stringify(this.searchFilteredResources));
      this.typeFilteredResources = this.filterFiles(null, this.selection, copy2)
      //console.log({ search: this.searchword, type: this.selection, result: this.countFilesByType(null, this.searchFilteredResources), result2: this.countFilesByType(null, this.typeFilteredResources) });
    }
  },

  computed: {
    anyResources() {
      return (this.resources.Files && this.resources.Files[0]) || (this.resources.Folders && this.resources.Folders[0])
    },

    searchword() {
      return this.$route.query?.resource_search;
    },

    filterChipsOptions() {
      return this.chipOptions(this.searchFilteredResources);
    },

    searchResultCount() {
      return this.countFilesByType(null, this.typeFilteredResources);
    },

    numberOfHiddenFiles() {
      //console.log(this.countFilesByType(null, this.resources), this.searchResultCount);
      return this.countFilesByType(null, this.resources) - this.searchResultCount;
    },

    hasFilters() {
      return this.searchword || this.selection;
    },
  },

  watch: {
    searchword: function () {
      this.filter();
    },
    selection: function () {
      this.filter();
    },
    loading: function () {
      this.filter();
    },
  },
};
</script>


<style lang='scss'></style>