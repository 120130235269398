<template>
    <v-card :color="count ? color : 'disabled darken-1'" dark class="px-2 d-flex align-center justify-center"
        style="border-radius: 12px; font-size: 13px; line-height: 1.5;" height="20" minWidth="20">
        {{ count }}
    </v-card>
</template>


<script>
export default {
    name: 'CountCard',
    props: {
        count: Number,
        color: {
            type: String,
            default: 'primary'
        },
    },
    components: {},

    data() {
        return {};
    },

    methods: {},

    computed: {},

    watch: {},
};
</script>


<style lang='scss' scoped></style>